import moment from 'moment';

export const formatDate = (date, format = 'DD MMMM YYYY') => {
  return moment(date).format(format);
};

export const formatContactEmail = (data) => {
  let htmlString =
    '<div><p>New contact form submitted from <a href="https://whitewoodtransport.com/contact">https://whitewoodtransport.com/contact</a></p>';
  htmlString = `${htmlString}<p>First Name: ${data.firstName}</p><p>Last Name: ${data.lastName}</p>`;
  htmlString = `${htmlString}<p>Email: ${data.emailAddress}</p>`;
  htmlString = `${htmlString}<p>Phone: ${data.phone}</p>`;
  if (data.state.length > 0) {
    htmlString = `${htmlString}<p>State: ${data.state}</p>`;
  }
  if (data.city.length > 0) {
    htmlString = `${htmlString}<p>City: ${data.city}</p>`;
  }
  if (data.zip.length > 0) {
    htmlString = `${htmlString}<p>Zip: ${data.zip}</p>`;
  }
  htmlString = `${htmlString}<p>Description: ${data.description}</p></div>`;
  return htmlString;
};

export const formatQuoteEmail = (data) => {
  let htmlString = `<div><p>First Name: ${data?.firstName}</p><p>Last Name: ${data?.lastName}</p>`;
  htmlString = `${htmlString}<p>Email: ${data?.emailAddress}</p>`;
  htmlString = `${htmlString}<p>Phone: ${data?.phone}</p>`;
  htmlString = `${htmlString}<p>Pickup Location: ${data?.pickupLocation}</p>`;
  htmlString = `${htmlString}<p>Drop off Location: ${data?.dropoffLocation}</p>`;
  htmlString = `${htmlString}<p>Commodity to haul: ${data?.commodityOfHaul}</p>`;
  htmlString = `${htmlString}<p>Date of Pickup: ${
    data?.date || 'n/a'
  }</p>`;
  htmlString = `${htmlString}<p>Company Name: ${
    data?.companyName || 'n/a'
  }</p>`;
  htmlString = `${htmlString}<p>Comment: ${
    data?.comment || 'n/a'
  }</p></div>`;
  return htmlString;
};

export const showSnackbar = (message) => {
  const snackbar = document.getElementById('snackbar');
  if (snackbar) {
    snackbar.className = 'show';
    snackbar.textContent = message;
    setTimeout(() => {
      snackbar.className = snackbar.className.replace('show', '');
    }, 3000);
  }
};
