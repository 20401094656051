const TestimonialCard = ({ comment, name, company }) => {
  return (
    <div className="carouselItem d-flex direction-column">
      <p className="text-italic font-size-22 pb-2">{comment}</p>
      <div className="center direction-column">
        <h3 className="font-BebasNeue font-size-45">{name}</h3>
        <span className="text-primary-red">{company}</span>
      </div>
    </div>
  );
};

export default TestimonialCard;
