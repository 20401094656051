import images from "../../assets/images";
import VerticalSpacer from "../../components/common/VerticalSpacer";
import { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { routeNames } from "../../utils/constants";
import { dummyBlogsListData } from "../../utils/data";
import parse from 'html-react-parser';

const BlogDetail = () => {
  const { slug } = useParams();
  const post = dummyBlogsListData[slug];

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div>
      <div className="header header-3">
        <img src={images.headerImage} alt="trucks" />
      </div>

      <VerticalSpacer factor={18} />
      <Container className="blog-detail-container">
        <Link className="back-container" to={routeNames.blogListing}>
          <img src={images.chevronLeft} alt="" />
          <span>Back</span>
        </Link>
        <VerticalSpacer factor={3} />
        <h1 className="font-BebasNeue text-center">{post.title}</h1>
        <p className="text-center font-size-24">
          Posted By <span className="text-primary-red">{post.postBy}</span> on {post.postDate}
        </p>
        <div className="center ">
          <Image
            src={post.image}
            alt={post.title}
            className="blog-detail-image fluid"
          />
        </div>
        <VerticalSpacer factor={3} />
        <div className="blog-detail-description-container">
          {parse(post.content)}
        </div>
      </Container>
    </div>
  );
};

export default BlogDetail;
