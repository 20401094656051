import { Modal } from 'react-bootstrap';

const SuccessAlert = ({ show, onClose, heading, description }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="button submit-button border-0">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessAlert;
