import images from "../assets/images";
import FeatureChip from "../components/chips/FeatureChip";
import VerticalSpacer from "../components/common/VerticalSpacer";
import AppNavbar from "../layouts/AppNavbar";
import Footer from "../layouts/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routeNames } from "../utils/constants";
import { features } from "../utils/data";

const AboutUsPage = () => {
  return (
    <div>
      <AppNavbar />
      <div className="header">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-primary-red">Driven </span>
          <span className="text-white">by efficiency </span>
          <br />
          <span className="text-white">delivering </span>
          <span className="text-primary-red">Excelence</span>
          <br />
        </h1>
        <span className="sub-heading text-white font-DMSans text-italic">
          Experience, Commitment, Expertise, Innovation
        </span>
      </div>
      <VerticalSpacer factor={4.12} />
      <Container fluid className="about-section-1">
        <div className="about-us-1">
          <p className="text-white">
            At Whitewood, we strive to consistently and efficiently deliver on
            every commitment we make. Every aspect of operations - cost, time,
            and service control measures - is optimized to ensure that projects
            are completed on track and on time, expectations are met, and
            promises are fulfilled.
          </p>
          <p className="text-white">
            Whitewood Transport began in 1972 in Whitewood, South Dakota, when a
            local construction company started a small trucking company —
            Whitewood Transportation. We believe the humble beginning of
            Whitewood Transport helped us map out our success today. Whitewood
            started small, mastered the fine points and kept the focus of
            providing safe and affordable transportation solutions to our
            customers.
          </p>
        </div>
        <Row>
          <Col lg={6} />
          <Col lg={6} className="about-us-image">
            <div className="about-us-content">
              <h2 className="text-white font-BebasNeue text-center">
                Driven to
                <br />
                <span className="text-primary-red">Deliver</span>
              </h2>
            </div>
            <img src={images.bigTruck} alt="" />
          </Col>
        </Row>
      </Container>
      <Container className="the-whitewood-container pt-5 pb-5">
        <h2 className="font-BebasNeue text-center pb-3">
          The WHITEWOOD <span className="text-primary-red">Way</span>
        </h2>
        <Row>
          <Col lg={2} />
          <Col lg={8}>
            <div className="about-features">
              {features.map((d) => (
                <FeatureChip key={d} title={d} />
              ))}
            </div>
          </Col>
          <Col lg={2} />
        </Row>
      </Container>
      <div className="h-section-6">
        <div className="h-section-6-img-cont">
          <img src={images.truck3} alt="trucks" />
        </div>
        <div className="center direction-column h-100">
          <h2 className="font-BebasNeue text-white text-center">
            Ready to elevate your heavy haul experience?
          </h2>
          <div>
            <Link
              to={routeNames.instantQuotePage}
              className="button submit-button center"
              style={{ maxWidth: "300px" }}
            >
              Instant Quote
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
