import images from "../../assets/images";
import BlogCard from "../../components/cards/BlogCard";
import VerticalSpacer from "../../components/common/VerticalSpacer";
import { Col, Container, Row } from "react-bootstrap";
import { dummyBlogsListData } from "../../utils/data";

const BlogList = () => {
  return (
    <div>
      <div className="header header-2">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container header-content-container-2">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-white">Blogs</span>
        </h1>
      </div>
      <VerticalSpacer factor={4} />
      <Container className="mb-4 blog-list-container">
        <h1 className="text-center pb-4">
          <span className="text-primary-red">Latest </span>Articles
        </h1>
        <Row>
          {Object.values(dummyBlogsListData).map((blog) => (
            <Col md={6} lg={4} className="mt-4" key={blog.id}>
              <BlogCard blog={blog} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BlogList;
