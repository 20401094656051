import { useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { sendContactEmail } from "../../services/apis";
import { formatContactEmail, showSnackbar } from "../../utils/helpers";
import Snackbar from "../toasts/Snackbar";
import { responseMessages } from "../../utils/constants";

const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
    state: "",
    city: "",
    zip: "",
    description: "",
  });
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  const _handleChange = (name, value) => {
    setFormState((st) => ({ ...st, [name]: value }));
  };

  const _handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    setSendEmailLoading(true);
    const data = {
      to:
        process.env.NODE_ENV !== "production"
          ? [process.env.REACT_APP_DEV_EMAIL_TO]
          : [process.env.REACT_APP_PROD_EMAIL_TO],
      subject: "Website Contact Form Submitted",
      content: formatContactEmail(formState),
    };
    try {
      const response = await sendContactEmail(data);
      if (response && response.ok) {
        showSnackbar(responseMessages.contactFormSuccess);
      } else {
        showSnackbar(responseMessages.contactFormFailure);
      }
    } catch (err) {
      showSnackbar(responseMessages.contactFormFailure);
    }
    setSendEmailLoading(false);
  };

  return (
    <Container fluid>
      <Form
        method="POST"
        id="contact-form"
        onSubmit={_handleFormSubmit}
        validated={validated}
        noValidate
      >
        <p className="font-size-24" style={{ fontWeight: 400 }}>
          To be contacted by a Whitewood Transport representative please provide
          the following:
        </p>
        <Row>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                First Name <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  name="firstName"
                  type="text"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  style={{ borderWidth: "1.8px" }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the first name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Last Name <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  name="lastName"
                  type="text"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  style={{ borderWidth: "1.8px" }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the last name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Email Address <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  name="emailAddress"
                  type="email"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  style={{ borderWidth: "1.8px" }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {formState.emailAddress
                    ? "Please enter a valid email address"
                    : "Please enter an email address"}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Phone <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  name="phone"
                  type="text"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  style={{ borderWidth: "1.8px" }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the phone number
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>State</Form.Label>
              <InputGroup hasValidation={false}>
                <Form.Control
                  name="state"
                  type="text"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  style={{ borderWidth: "1.8px" }}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                type="text"
                onChange={(e) => _handleChange(e.target.name, e.target.value)}
                style={{ borderWidth: "1.8px" }}
              />
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>Zip</Form.Label>
              <Form.Control
                isValid
                name="zip"
                type="text"
                onChange={(e) => _handleChange(e.target.name, e.target.value)}
                style={{ borderWidth: "1.8px" }}
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>
                Description <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  name="description"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  style={{ borderWidth: "1.8px" }}
                  as="textarea"
                  rows={4}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the description
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="contanct-form-button-container">
          <button
            disabled={sendEmailLoading}
            type="submit"
            className="button submit-button contact-submit-button"
          >
            {sendEmailLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Form>
      <Snackbar />
    </Container>
  );
};

export default ContactForm;
