import VerticalSpacer from "../components/common/VerticalSpacer";
import AppNavbar from "../layouts/AppNavbar";
import Footer from "../layouts/Footer";
import { Outlet } from "react-router-dom";

const BlogPage = () => {
  return (
    <div>
      <AppNavbar />
      <Outlet />
      <VerticalSpacer factor={6} />
      <Footer />
    </div>
  );
};

export default BlogPage;
