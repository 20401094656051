// Routes
export const routeNames = {
  homepage: "/",
  instantQuotePage: "/instant-quote",
  ownerOperators: "/owner-operators",
  aboutUs: "/about-us",
  contact: "/contact",
  blogListing: "/blog",
  gallery: "/gallery",
};

export const strings = {
  home: "Home",
  instantQuote: "Instant Quote",
  ownerOperators: "Owner Operators",
  blogs: "Blogs",
  gallery: "Gallery",
  aboutUs: "About Us",
  contact: "Contact",
  getAQuote: "GET INSTANT QUOTES NOW!",
  heavyEquipment: "Heavy Equipment and Machinery",
  buildingMaterials: "Building Materials",
  pipeIndustrial: "Pipe/Industrial",
  military: "Military",
  others: "Others",
  ourVision:
    "Our vision is to provide convenience and help increase your sales business.",
};

export const timezoneOptions = () => {
  const timezone = {
    "America/Los_Angeles": "Pacific Time - US and Canada",
    "America/Edmonton": "Mountain Time - US and Canada",
    "America/Winnipeg": "Central Time - US and Canada",
    "America/New_York": "Eastern Time - US and Canada",
    "America/Halifax": "Atlantic Time",
  };
  return timezone;
};

export const GoogleAutoCompleteOptions = {
  API_KEY: "AIzaSyD3Srz6dpiK1Pl6CcC9r5ZfVQ9XXCJ6sWs",
  fields: ["geometry", "formatted_address", "address_components"],
  countries: ["us", "ca"],
};

export const appConstants = {
  equipmentTypes: [
    { title: "Van", value: "van" },
    { title: "Reefer", value: "reefer" },
    { title: "Flatbed", value: "flatbed" },
    { title: "Step Deck", value: "stepDeck" },
    { title: "Power Only", value: "powerOnly" },
    { title: "Conestoga", value: "conestoga" },
    { title: "Box Truck", value: "boxTruck" },
    { title: "Sprinter", value: "sprinter" },
    { title: "Drayage", value: "drayage" },
  ],
};

export const quoteFormConfigs = {
  carrierCompanyId: "36",
  truckList: "van,reefer,sprinter,drayage",
};

export const apiRoutes = {
  sendEmail: "email/public/send",
};

export const responseMessages = {
  contactFormSuccess: "Your request has been submitted.",
  contactFormFailure: "Something went wrong, please try again later.",
  whitewoodQouteFormSuccess:
    "Thank you for submitting a quote with Whitewood Transport. A salesmen will be in touch with you shortly.",
};
