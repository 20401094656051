import images from "../assets/images";
import { strings } from "./constants";

export const trucksQuoteData = [
  {
    id: "1",
    text: strings.heavyEquipment,
    icon: images.truck2,
  },
  {
    id: "2",
    text: strings.buildingMaterials,
    icon: images.truck,
  },
  {
    id: "3",
    text: strings.pipeIndustrial,
    icon: images.semiTruck,
  },
  {
    id: "4",
    text: strings.military,
    icon: images.military,
  },
  {
    id: "5",
    text: strings.others,
    icon: images.crane,
  },
];

export const faqsData = [
  {
    id: "1",
    question: "Where does Whitewood Transport operate?",
    answer:
      "Whitewood Transport operates in the contiguous United States, Alaska, and most provinces of Canada.",
  },
  {
    id: "2",
    question: "How much prior notice do I need to schedule a pickup?",
    answer:
      "We prefer 48 hours of prior notice, but we can respond in as little as one hour. Often times, flexible shipping dates can result in better pricing.",
  },
  {
    id: "3",
    question: "What are your hours of operation?",
    answer:
      "6:30 am to 5:30 pm Monday through Friday with emergency contact 24/7/365 by a live answering service — no voicemail!",
  },
  {
    id: "4",
    question: "What types of commodities can you haul?",
    answer:
      "We are a general commodities carrier emphasizing in oversize and overweight. Whitewood specializes in machinery of all types.",
  },
  {
    id: "5",
    question: "How do I pay for services?",
    answer:
      "Whitewood extends credit to companies that can prove credit worthiness. We offer options in prepayment, wire transfer, and most credit cards (4% fee applies).",
  },
  {
    id: "6",
    question:
      "What information do I need to provide in order to obtain a transport quote?",
    answer:
      "Origin, destination, dimensions, weight, shipment value, estimated date of shipment, commodity type, name and model if applicable.",
  },
  {
    id: "7",
    question: "Is my freight covered by insurance?",
    answer:
      "You bet. Whitewood maintains cargo coverage up to $1,000,000.00 per shipment. Additional coverage is available for a fee.",
  },
  {
    id: "8",
    question: "If I cancel my order, will I be charged?",
    answer:
      "It depends on whether or not a truck has been dispatched. Applicable charges will apply.",
  },
  {
    id: "9",
    question: "What are your payment terms?",
    answer: "Net 15 with a contract. Options are available and negotiable.",
  },
  {
    id: "10",
    question: "How do you calculate costs?",
    answer: "It all depends on time, distance, size, and weight.",
  },
  {
    id: "11",
    question: "What is the largest size you can haul?",
    answer:
      "Depending on origin and destination, up to 130,000 pounds per single piece of freight.",
  },
  {
    id: "12",
    question: "What is your primary mileage rate?",
    answer:
      "Rates are determined by equipment need, commodity type, origin, destination, weight, dimension, and special needs.",
  },
  {
    id: "13",
    question: "What if fuel or operational cost suddenly rise?",
    answer:
      "Whitewood charges a fuel surcharge on all shipments while fuel costs exceed $1.70 per gallon. FSC is predicted based on the Department of Energy’s published fuel prices by region. Whitewood typically adjusts FSC weekly, but reserves the right to adjust daily with sudden spikes in fuel cost.",
  },
  {
    id: "14",
    question: "What if escort drivers are required?",
    answer:
      "Typically, we know in advance if escorts will be needed and charges are included in the rate quote. If there is a change of conditions (unknown conditions including weather and road construction) you will be notified as soon as possible.",
  },
  {
    id: "15",
    question: "What about special state regulations for certain freight?",
    answer:
      "Whitewood is familiar with most state regulations and typically is able to adjust to state needs and requirements.",
  },
  {
    id: "16",
    question: "Can I receive updates on the status of my shipment?",
    answer:
      "With Satellite Verified Transport, we can locate your shipment on a Whitewood truck to within 100 feet at any given time and communicate directly with the driver.",
  },
  {
    id: "17",
    question: "What if there is a problem with my shipment?",
    answer:
      "While Whitewood takes every possible step to ensure your shipment is problem-free, issue can arise even with the best planning. If there is a change in the condition of your shipment, please contact your Whitewood account representative as soon as possible for assistance.",
  },
];

export const testimonialsData = [
  {
    id: "1",
    name: "BRIAN ZWEEGMAN",
    company: "United Rentals, Billings, MT",
    comment:
      "We use Whitewood because they have great communication and get our equipment to us when we need it. They're trustworthy and let us know if they can or can't do a run. Some other companies take the order and hope for the best, but we know Whitewood will never do that.",
  },
  {
    id: "2",
    name: "JOSH RENO",
    company: "Westate Machinery Co., Billings, MT",
    comment:
      "Whitewood Transport is an excellent company to work with and their team of heavy haul experts are second to none. We can always count on Whitewood to deliver exactly what was promised!",
  },
  {
    id: "3",
    name: "BILL EARL",
    company: "Iron Man Equipment, Meridian, ID",
    comment:
      "Most of our loads are not of the “load it and go” type. Whitewood has always come through for us and never made a “problem” out of difficult situations. Your drivers and staff have always gone the extra mile to see that we get superior service. This is why we use Whitewood for all our hauling.",
  },
  {
    id: "4",
    name: "GRANSON JONES",
    company: "Ransome Caterpilllar, Bensalem, PA",
    comment:
      "We have used Whitewood Transport for the last five years. Delivers are made on time as promised and all quotes have been Dead Nut on the Money. Hats off to Kevin and Whitewood.",
  },
  {
    id: "5",
    name: "JEREMY MILLER",
    company: "Anderson Service, Inc., Billings, MT",
    comment:
      "Since using Whitewood, we have lowered our transportation costs considerably. They are very reliable and quick to respond to all of our needs. Every one of their drivers has been professional and great to deal with.",
  },
  {
    id: "6",
    name: "DYKE JONES",
    company: "Western States Equipment Company, Meridian, ID",
    comment:
      "Simple as this…fair quoted rates and you do what you say you will do. I want to leave my transportation needs up to the professionals so I can buy and sell equipment.",
  },
  {
    id: "7",
    name: "DAWN SECORD ",
    company: "Steven Steel West, Inc., Billings, MT",
    comment:
      "Stevens Steel West uses Whitewood Transport on a regular basis because of their courteous dispatch staff, their timely response and their enthusiasm to help our company get freight to our customers on time.",
  },
  {
    id: "8",
    name: "TOMMY MCDANIEL ",
    company: "F&H Mine Supply, Inc., Absarokee, MT",
    comment:
      "Whitewood Transport is our first call for all our freight needs here at F&H Mine Supply, Inc. The drivers and dispatchers are top notch and the best I have been around in over 40 years of constitution and mining.",
  },
  {
    id: "9",
    name: "TIM VETCHES",
    company: "Core & Main, Billings, MT",
    comment:
      "Why use Whitewood Transport? The biggest reason is their people. They all go above and beyond to get the job done.",
  },
  {
    id: "10",
    name: "ROB LEACH",
    company: "H&E Equipment Services, Henderson, CO",
    comment:
      "Whitewood does what they say they are going to do and at a reasonable price. They are my go-to haulers.",
  },
  {
    id: "11",
    name: "D.J. MARQUEZ",
    company: "Outfront Transport, Menahga, MN",
    comment:
      "The customer service and staff at Whitewood Transport is unparalleled. Awesome people, good freight and great",
  },
  {
    id: "12",
    name: "LARRY PITZER",
    company: "Atkinson Construction, Golden, CO",
    comment:
      "Whitewood Transport is very dependable and gets the job done when we need hauling.",
  },
];

export const features = [
  "Daily Owner Involvement",
  "Satisfactory Safety Rating",
  "Experts in Loading and Tie-Down Securement",
  "Fully Automated, Integrated Order Processing",
  "Dedicated Dispatch",
  "Immediate Response",
  "On-time Delivery",
  "24-hour Service",
  "Live and Experience Dispatch staff",
  "Hazmat Trained and Certified",
  "Knowledge of State Permit Regulations",
  "Experts in Equipment Types and Heavy Haul Requirements",
  "Experienced Owner Operators",
  "Licensed and Insured",
];

export const dummyBlogsListData = {
  "whitewood-transport-selected-to-haul-the-2017-u-s-capitol-christmas-tree": {
    id: "1",
    slug: "whitewood-transport-selected-to-haul-the-2017-u-s-capitol-christmas-tree",
    image: images.whitewoodChristmasTree,
    title:
      "WHITEWOOD TRANSPORT SELECTED TO HAUL THE 2017 U.S. CAPITOL CHRISTMAS TREE",
    postBy: "Marina Woodson",
    postDate: "October 25, 2017",
    updatedAt: "25 Octo",
    readingTime: "20 mins",
    content: `
              <h2 class="bolg-heading mb-4">
                Billings-based carrier will transport the People's Tree from Montana to Washington D.C.
              </h2>
              <p class="font-size-24">
                Billings, Mont., October 25, 2017 — When Santa was looking for a sleigh worthy of hauling one of his most special Christmas trees, 
                years of being on his “Nice List” paid off for Whitewood Transport of Billings, MT. <span class="highlight">The company was selected to transport the 2017 U.S.
                Capitol Christmas Tree due to their excellent reputation in Montana's trucking industry, according to Bruce Ward, president of Choose 
                Outdoors, a non-profit organization that assists the U.S. Forest Service with coordinating the annual tour.</span>
              </p>
              <p class="font-size-24">
                Whitewood Transport is a recent multiple-year recipient of the Motor Carrier of the Year, including the 2016 award, from the 
                Motor Carriers of Montana. “When we starting searching for the company to be entrusted with transporting the People's Tree, 
                we heard from several sources that we needed to get in touch with Whitewood Transport,” Ward said. “After traveling to Montana
                 and meeting personally with Mike, Brad and members of the Whitewood team, it quickly became clear that they really understand
                the magnitude of this undertaking. The pride and honor that they displayed in being considered for this important task was a 
                clear and immediate indication that Whitewood was who we should select. Since being chosen to transport this year's trees to 
                the Capitol, Whitewood has exceeding our expectations.”
              </p> 
              <p class="font-size-24"> 
                “It is an honor to be selected to carry the 2017 U.S. Capitol Christmas tree and to represent the great state of Montana 
                during the tree's journey to Washington, D.C.,” says Mike Wilson, owner of Whitewood Transport. “Being able to deliver this 
                special gift to the nation safely is a great reflection of the vision and mission of Whitewood. One of our company values 
                is to respect and support the community through gifts and service, and I can't think of a better way for Whitewood to honor 
                this value.”
              </p>
              <p class="font-size-24"> 
                Whitewood Transport was also chosen to haul the 2017 U.S. Capitol Christmas Tree based on the reputation their drivers have 
                in the trucking industry. Larry Spiekermeier, who has 1.6-million accident free miles with the company, was selected to 
                make the drive from the Kootenai National Forest in northwest Montana on a special Kenworth T680 Advantage, which will haul 
                a trailer specially designed for the U.S. Capitol Christmas Tree Tour. The Kenworth T680 Advantage's distinctive decal design 
                features the 2017 U.S. Capitol Christmas Tree - Kootenai National Forest seal, brightly lit and colorfully adorned Christmas 
                Tree, and the U.S. Capitol beneath a starry sky with the words “Big Sky. Big Tree. Big Journey.” The tour will make twenty 
                stops in Montana, North Dakota, South Dakota, Missouri and Kentucky before arriving at the Capitol, including a stop near 
                Spiekermeier's hometown of Fargo, ND, in Grand Forks.
              </p>
              <p class="font-size-24">
                Whitewood will have two trucks making the trip: the truck with the People's Tree driven by Speikermeier and a second truck 
                with 70 smaller trees eight pallets of Made in Montana ornaments donated by several communities and sponsors of the tour. 
                The smaller trees will be installed in various rooms at the Capitol.
              </p>
              <p class="font-size-24">
                Spiekermeier was named Motor Carriers of Montana “Driver of the Year” in 2009 and 2015 and has over 1.6 million accident-free 
                miles behind the wheel for Whitewood Transport. Hauling specialized equipment with a payload of 100,000 to 130,000 pounds is 
                second nature for him. Spiekermeier's career in trucking began in his early twenties when he hauled cement, produce, hot oil 
                and logs to pay for college. Spiekermeier soon realized he could make a great living and enjoy the open road as a driver. 
                Spiekermeier has been a valuable member of the Whitewood team of Owner Operators for almost 20 years. His knowledge of 
                equipment, loading and transport, and his experience with specialty oversize and overweight commodities set Spiekermeier 
                apart in the trucking industry. During his career with Whitewood Transport, he has truly been an asset, friend, and mentor 
                to all he works with. “Larry is easy to get along with and he deals with problems professionally,” says dispatcher Ray Arlint.
              </p>
              <p class="font-size-24">
                Spiekermeier and his wife Mary Ann have three grown children; Mandy, Ryan, and Cody. They reside in a beautiful home 
                overlooking the river in Plains, MT, and enjoy working in their yard and admiring the wild turkeys, deer, and black bear 
                that frequently visit them. Mary Ann will ride along with Larry on the first 900 miles of the tre's journey to the Capitol. 
                At each stop, Spiekermeier and his road crew will visit with those attending the event about this once-in-a-lifetime 
                adventure. Whitewood Transport and Spiekermeier have also got a few surprises to hand out to the kids at each stop. Whitewood 
                is supporting the tour in many ways, including posts on social media encouraging people to come and “Look for Larry” at each 
                stop.
              </p>
              <p class="font-size-24 mt-5">
                Visit 
                <a href="http://www.whitewoodchristmas2017.com/"  target="_blank" class="text-primary-red">
                  www.WhitewoodChristmas2017.com
                </a> 
                for more information on the tree and Whitewood's journey to the Capitol.
              </p>
              <p class="font-size-24 mt-5">
                Listen to Whitewood Transport's Owner, Mike Wilson, as he discusses Whitewood Transport as a company and their role in 
                transporting the Capitol's Christmas tree.  
                <a href="https://whitewoodtransport.com/wp-content/uploads/2018/09/Mike-Wilson-Interview_11152017.mp3" target="_blank" class="text-primary-red">
                  Sirius Radio Interview
                </a>
              </p>
             `,
  },
  "whitewoods-jim-self-named-2022-american-trucking-associations-ata-national-driver-of-the-year":
    {
      id: "2",
      slug: "whitewoods-jim-self-named-2022-american-trucking-associations-ata-national-driver-of-the-year",
      image: images.whitewoodNantionalDriverOfYear,
      postBy: "Robert Kahler",
      postDate: "April 18, 2023",
      title:
        "WHITEWOOD'S JIM SELF NAMED 2022 AMERICAN TRUCKING ASSOCIATIONS (ATA) NATIONAL DRIVER OF THE YEAR",
      updatedAt: "18 April",
      readingTime: "20 mins",

      content: `<p class="font-size-24">
                Whitewood Transport is excited to announce that Jim Self of Rathdrum, ID has been named The American Trucking Association's 
                (ATA) 2022 National Driver of the Year. This prestigious award is presented to the driver who exemplifies excellence in safety, 
                customer service, and professionalism. Jim was selected by a panel of judges representing the trucking industry. Prior to winning 
                National Driver of the Year, Jim was selected as the Montana Trucking Association's Driver of the Month for September 2021 and 
                Driver of the Year for 2022. Jim has been driving professionally for 47 years, driving over 6 million miles without an accident. 
                In addition to his impeccable safety record, Jim is known for his outstanding customer service, often being requested by name by 
                Whitewood's customers. He always goes above and beyond to ensure that his customers' needs are met and that their freight arrives 
                safely. “I am honored to receive this award,” said Jim. “Driving is not just a job for me, it's what I love to do. I take great 
                pride in my work and in providing the best possible service to our customers. This award is a testament to the hard work and 
                dedication of not just myself, but my colleagues at Whitewood and the entire transportation industry.
              </p> 
              <p class="font-size-24"> 
                As the Driver of the Year, Jim was recognized by the American Trucking Associations Safety Management Council in Minneapolis MN 
                and will receive further recognition at ATAs Management Conference & Exhibition (MCE) in Austin, TX on October 14. 
              </p>`,
    },
};
