import Crane from './Crane.svg';
import Military from './Military.svg';
import SemiTruck from './SemiTruck.svg';
import Truck from './Truck.svg';
import Truck2 from './Truck2.svg';
import Facebook from './Facebook.png';
import Twitter from './Twitter.svg';
import Instagram from './Instagram.svg';
import TickGreen from './TickGreen.svg';
import FilledStar from './FilledStar.svg';
import Plus from './Plus.svg';
import Close from './Close.svg';
import ChevronLeft from './ChevronLeft.svg';
import LinkedIn from './LinkedIn.png';

const images = {
  wtLogo: require('./WtLogo.png'),
  headerImage: require('./Header.png'),
  rectangleRed: require('./Rectangle.png'),
  rectangleRed2: require('./Rectangle2.png'),
  craneImage: require('./CraneImg.png'),
  truckImage: require('./TruckImg.png'),
  construction: require('./Construction.png'),
  recordJed: require('./RecordJed.png'),
  montanaTrucking: require('./MontanaTrucking.png'),
  truck3: require('./Truck3.png'),
  whitewoodChristmasTree: require('./whitewood-christmas-tree.jpg'),
  whitewoodNantionalDriverOfYear: require('./whitewood-nantional-driver-of-year.jpeg'),
  bigTruck: require('./BigTruck.jpeg'),
  galleryImage_1: require('./gallery/Bobby Danford Knife River Cement Drum 178453 20231212.jpg'),
  galleryImage_2: require('./gallery/20231026 McCauley Tom.jpg'),
  galleryImage_3: require('./gallery/20230822.1 Moline Bill.jpg'),
  galleryImage_4: require('./gallery/20231208 Smith Codey.jpg'),
  galleryImage_5: require('./gallery/IMG_1612.jpg'),
  galleryImage_6: require('./gallery/20231211 Munsch Troy.jpg'),
  galleryImage_7: require('./gallery/Adam Pierce VA Transformer Parts 178970 20240207.jpg'),
  galleryImage_8: require('./gallery/20230823.1 Hlebechuk Matt.jpg'),
  galleryImage_9: require('./gallery/20231215 Rush Tim.jpg'),
  galleryImage_10: require('./gallery/Mike Ralph D6 20240213.jpg'),
  galleryImage_11: require('./gallery/20231031.1 Carmichael Wayne.jpg'),
  galleryImage_12: require('./gallery/Codey Smith - Screen Plant.JPG'),
  galleryImage_13: require('./gallery/IMG_0823.jpg'),
  galleryImage_14: require('./gallery/Tim Rush 04.11.jpg'),
  galleryImage_15: require('./gallery/Tim Rush CAT 637 Water Wagon.1.jpg'),
  galleryImage_16: require('./gallery/Tom McCauley CAT 990.JPG'),
  chevronLeft: ChevronLeft,
  crane: Crane,
  military: Military,
  semiTruck: SemiTruck,
  truck: Truck,
  truck2: Truck2,
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  tickGreen: TickGreen,
  filledStar: FilledStar,
  plus: Plus,
  close: Close,
  linkedIn: LinkedIn,
};

export default images;
