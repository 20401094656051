import images from '../assets/images';
import VerticalSpacer from '../components/common/VerticalSpacer';
import AppNavbar from '../layouts/AppNavbar';
import Footer from '../layouts/Footer';
import { Col, Container, Row } from 'react-bootstrap';

const GalleryPage = () => {
  return (
    <div>
      <AppNavbar />
      <div className="header header-2">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container header-content-container-2">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-white">Gallery</span>
        </h1>
      </div>
      <VerticalSpacer factor={3} />

      <div className="gallery-page-container">
        <Container>
          <Row xs={1}>
            <Col md={5}>
              <img
                src={images.galleryImage_1}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
            <Col md={7} className="gallery-custom-padding-xs">
              <img
                src={images.galleryImage_2}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
            <Col className="pt-5" md={12}>
              <img
                src={images.galleryImage_3}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>

            <Col md={12} className="pt-5">
              <Row>
                <Col md={6}>
                  <Row className="h-100">
                    <Col md={12}>
                      <img
                        src={images.galleryImage_4}
                        alt="gallery"
                        className="w-100 h-100 object-fit-cover rounded"
                      />
                    </Col>

                    <Col md={12} className="pt-5">
                      <img
                        src={images.galleryImage_5}
                        alt="gallery"
                        className="w-100 h-100 object-fit-cover rounded"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={6} className="gallery-custom-padding-xs">
                  <Row className="h-100">
                    <Col md={12}>
                      <img
                        src={images.galleryImage_6}
                        alt="gallery"
                        className="w-100 h-100 object-fit-cover rounded"
                      />
                    </Col>

                    <Col md={12} className="pt-5">
                      <img
                        src={images.galleryImage_7}
                        alt="gallery"
                        className="w-100 h-100 object-fit-cover rounded"
                      />
                    </Col>

                    <Col md={12} className="pt-5">
                      <img
                        src={images.galleryImage_8}
                        alt="gallery"
                        className="w-100 h-100 object-fit-cover rounded"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col md={12} className="pt-5">
              <img
                src={images.galleryImage_9}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>

            <Col md={6} className="pt-5">
              <img
                src={images.galleryImage_10}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>

            <Col md={6} className="pt-5">
              <img
                src={images.galleryImage_11}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pt-5">
              <img
                src={images.galleryImage_12}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
          </Row>

          <Row>
            <Col md={12} className="pt-5">
              <img
                src={images.galleryImage_14}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pt-5">
              <img
                src={images.galleryImage_15}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pt-5">
              <img
                src={images.galleryImage_16}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pt-5">
              <img
                src={images.galleryImage_13}
                alt="gallery"
                className="w-100 h-100 object-fit-cover rounded"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <VerticalSpacer factor={3} />
      <Footer />
    </div>
  );
};

export default GalleryPage;
