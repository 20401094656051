import images from "../assets/images";
import TestimonialsCarousel from "../components/carousels/TestimonialsCarousel";
import VerticalSpacer from "../components/common/VerticalSpacer";
import FaqSection from "../components/sections/FaqSection";
import AppNavbar from "../layouts/AppNavbar";
import Footer from "../layouts/Footer";
import { Col, Container, Row } from "react-bootstrap";

const OwnerOperatorsPage = () => {
  return (
    <div>
      <AppNavbar />
      <div className="header">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-primary-red">Professionalism</span>
          <span className="text-white">, Respect </span>
          <br />
          <span className="text-white">And </span>
          <span className="text-primary-red">personal Pride</span>
          <br />
        </h1>
        <VerticalSpacer factor={2} />
        <div>
          <a
            className="button2 submit-button center"
            style={{ width: "300px", textDecoration: "none"}}
            role="button"
            href="https://intelliapp.driverapponline.com/c/whitewoodtransport?uri_b=ia_whitewoodtransport_1544865453"
            target="blank"
          >
            Join Us Today!
          </a>
        </div>
      </div>
      <VerticalSpacer factor={5} />
      <Container className="position-relative center text-center">
        <div className="o-section-1">
          <p className="font-size-24">
            At Whitewood, we contract with the best people and provide them with
            the tools, resources, and professional support to grow—to be
            professional, to earn respect, and to feel pride in their jobs and
            the company for whom they work. Quality operators reflect a quality
            company.
          </p>
          <p className="font-size-24">
            Take the Road Best Traveled and Join Whitewood’s Elite Team of Owner
            Operators.
          </p>
          <p className="font-size-24 text-primary-red font-bold text-italic">
            Or Call 24-Hours a Day, Seven Days a Week (800) 228.5569
          </p>
        </div>
      </Container>
      <Container fluid className="o-testimonials text-center">
        <img src={images.rectangleRed} alt="rectangle" />
        <h2 className="font-BebasNeue pt-5">Testimonials</h2>
        <div className="pt-4 pb-4">
          <TestimonialsCarousel />
        </div>
      </Container>
      <div className="o-section-2">
        <div className="o-section-2-img-cont">
          <img src={images.truckImage} alt="trucks" />
        </div>
        <div className="o-section-2-content-container direction-column">
          <div className="o-section-2-1">
            <h2 className="text-white font-BebasNeue text-center">
              <span className="text-primary-red">Whitewood Transport </span>
              is what you are looking for
            </h2>
            <p className="text-white text-center font-size-24">
              At Whitewood, we contract with the best people and provide them
              with the tools, resources, and professional support to grow—to be
              professional, to earn respect, and to feel pride in their jobs and
              the company for whom they work. Quality operators reflect a
              quality company.
            </p>
          </div>
          <VerticalSpacer />
          <Container>
            <Row>
              <Col lg={1} sm={12} />
              <Col lg={5} sm={12}>
                <div className="o-list-container">
                  <ul>
                    <li>Weekly Settlements with Direct Deposit</li>
                    <li>Incentives and Bonuses Available</li>
                    <li>Fuel Card Provided</li>
                  </ul>
                </div>
              </Col>
              <Col lg={5} sm={12}>
                <div className="o-list-container">
                  <ul>
                    <li>Satisfactory Safety Rating</li>
                    <li>Training and Cargo & Liability Insurance Provided</li>
                    <li>
                      Fuel Card ProvidedClean Driving Records Only Need Apply
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={1} sm={12} />
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <div className="pt-5">
          <iframe 
            className="white-wood-banquet-iframe"
            src="https://www.youtube.com/embed/IOJS7bHuxII?autoplay=1&mute=1" 
            title="WHITEWOOD BANQUET 2024" 
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          ></iframe>
        </div>
      </Container>
      <div className="faq-section center direction-column p-5">
        <h2 className="font-BebasNeue">
          Frequently Asked <span className="text-primary-red">Questions</span>
        </h2>
        <FaqSection />
      </div>
      {/* <Container
        fluid
        className="p-4 bg-primary-red center direction-column o-section-3"
      >
        <h2 className="text-white font-BebasNeue mb-4">Apply Here Instead</h2>
        <div
          className="button2 submit-button center bg-primary-dark"
          style={{ width: "220px" }}
        >
          Apply
        </div>
      </Container> */}
      <Footer />
    </div>
  );
};

export default OwnerOperatorsPage;
