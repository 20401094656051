import { Link } from "react-router-dom";

const BlogCard = ({ blog }) => {
  const { image, title, updatedAt, readingTime } = blog;

  return (
    <div className="blog-card position-relative">
      <img src={image} alt="" />
      <div className="blog-content">
        <h3 className="font-BebasNeue text-ellipsis">{title}</h3>
        <span>{updatedAt}</span>
        <span className="date-time-sep"></span>
        <span>{readingTime}</span>
      </div>
      <div className="center blog-button">
        <Link
          className="button back-button readmore-button"
          to={`/blog/${blog.slug}`}
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
