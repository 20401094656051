import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialCard from "../cards/TestimonialCard";
import { testimonialsData } from "../../utils/data";
import React from "react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const CustomDot = ({ onClick, ...rest }) => {
  const { active } = rest;

  return (
    <div
      className={active ? "active-dot" : "inactive-dot"}
      onClick={() => onClick()}
    ></div>
  );
};

const TestimonialsCarousel = () => {
  return (
    <Carousel
      autoPlay
      autoPlaySpeed={2000}
      infinite
      responsive={responsive}
      removeArrowOnDeviceType={["mobile", "desktop", "tablet"]}
      partialVisible
      showDots
      customDot={<CustomDot />}
      className="testimonial-carousel"
    >
      {testimonialsData.map((testimonial) => (
        <TestimonialCard
          key={testimonial.id}
          comment={testimonial.comment}
          name={testimonial.name}
          company={testimonial.company}
        />
      ))}
    </Carousel>
  );
};

export default TestimonialsCarousel;
