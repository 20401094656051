import images from "../assets/images";
import HorizontalSpacer from "../components/common/HorizontalSpacer";
import VerticalSpacer from "../components/common/VerticalSpacer";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routeNames, strings } from "../utils/constants";

const Footer = () => {
  return (
    <Container fluid className="bg-black">
      <Container className="pt-5 pb-5" style={{ padding: 0 }}>
        <Row>
          <Col xs={12} sm={3} lg={5} >
            <div>
              <img
                src={images.wtLogo}
                width="62"
                height="62"
                className="d-inline-block align-top"
                alt="WT logo"
              />
              <VerticalSpacer factor={2} />
              <p className="text-white font-w500">{strings.ourVision}</p>
              <VerticalSpacer factor={2} />
              <div className="d-flex align-center gap-4">
                <a
                  href="https://www.facebook.com/WhitewoodTransport?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={images.facebook} alt="Facebook" width={35} />
                </a>
                {/* <a
                  href="https://x.com/Whitewood1987?s=09"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={images.twitter} alt="Twitter" />
                </a> */}
                {/* <a
                  href="https://www.instagram.com/whitewoodtrans?igsh=MW1hcG42bTJlc3gydQ=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={images.instagram} alt="Instagram" />
                </a> */}
                <a 
                  href="https://www.linkedin.com/company/whitewood-transport-inc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={images.linkedIn} alt="linkedIn" width={35}/>
                </a>
              </div>
            </div>
          </Col>
          <Col />
          <Col />
          <Col xs={12} sm={3}>
            <ul className="footer-list">
              <li>About</li>
              <li>
                <Link to={routeNames.homepage}>{strings.home}</Link>
              </li>
              <li>
                <Link to={routeNames.aboutUs}>{strings.aboutUs}</Link>
              </li>
              <li>
                <Link to={routeNames.contact}>{strings.contact}</Link>
              </li>

              <li>
                <Link to={routeNames.instantQuotePage}>
                  {strings.instantQuote}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={3} lg={1}>
            <ul className="footer-list">
              <li>Socials</li>

              {/* <li>Discord</li> */}
              {/* <li>
                <a
                  href="https://x.com/Whitewood1987?s=09"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li> */}
              <li>
                <a
                  href="https://www.facebook.com/WhitewoodTransport?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/whitewood-transport-inc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr style={{ color: "#486284" }} />
        <div className="d-flex justify-sp-between text-white footer-privacy-links">
          <span>&copy; 2024 Whitewood Transport. All rights reserved</span>
          <div className="d-flex">
            <a
              href="https://whitewoodtransport.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy & Policy
            </a>
            <HorizontalSpacer factor={2} />
            <span>Terms & Condition</span>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Footer;
