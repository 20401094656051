import images from "../../assets/images";
import { useState } from "react";

const FaqCard = ({ question, answer }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div
      className={`faq-card ${
        opened ? "border-primary-red" : "border-black-200"
      } mt-2`}
    >
      <div
        className="d-flex justify-sp-between align-center faq-qustion-container"
        onClick={() => setOpened((st) => !st)}
      >
        <span className="text-primary-red font-size-20">{question}</span>
        <img src={opened ? images.close : images.plus} alt="Plus" />
      </div>
      {opened && <p className="mt-3 pe-2 font-size-20">{answer}</p>}
    </div>
  );
};

export default FaqCard;
