import images from '../assets/images';
import AppNavbar from '../layouts/AppNavbar';
import VehicleCard from '../components/cards/VehicleCard';
import HorizontalSpacer from '../components/common/HorizontalSpacer';
import VerticalSpacer from '../components/common/VerticalSpacer';
import GetQuoteForm from '../components/forms/GetQuoteForm';
import Footer from '../layouts/Footer';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { strings } from '../utils/constants';
import { trucksQuoteData } from '../utils/data';

const InstantQuotePage = () => {
  const [selectedVehicle, setSelectedVehicle] = useState();

  return (
    <div>
      <AppNavbar />
      <div className="header">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-primary-red">Driven </span>
          <span className="text-white">by efficiency </span>
          <br />
          <span className="text-white">delivering </span>
          <span className="text-primary-red">Excelence</span>
          <br />
        </h1>
        <span className="sub-heading text-white font-DMSans text-italic">
          Experience, Commitment, Expertise, Innovation
        </span>
        {/* <h2>Experience, Commitment, Expertise, Innovation</h2> */}
      </div>
      <div className="bg-white content-container">
        <VerticalSpacer factor={5} />
        <Container>
          <div className="d-flex justify-center align-center direction-column">
            <h2 className="font-BebasNeue quote-heading">
              {strings.getAQuote}
            </h2>
            <VerticalSpacer factor={2} />
            <div className="d-flex direction-row align-center justify-center">
              <div className="filled-circle" />
              <HorizontalSpacer factor={0.8} />
              <hr className="line" />
              <HorizontalSpacer factor={0.8} />
              <div className="borderred-circle" />
            </div>
          </div>
          <VerticalSpacer factor={3} />
          {selectedVehicle ? (
            <Container className="form-container">
              <GetQuoteForm
                onClickBack={() => setSelectedVehicle(undefined)}
                selectedVehicle={selectedVehicle?.text}
              />
            </Container>
          ) : (
            <Row>
              {trucksQuoteData.map((truck) => (
                <Col key={truck.id} className="mb-1 mt-1 vehicle-container">
                  <VehicleCard
                    icon={truck.icon}
                    text={truck.text}
                    onClick={() => setSelectedVehicle(truck)}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Container>
        <VerticalSpacer factor={5} />
      </div>
      <Footer />
    </div>
  );
};

export default InstantQuotePage;
