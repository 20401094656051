import images from "../assets/images";
import VerticalSpacer from "../components/common/VerticalSpacer";
import ContactForm from "../components/forms/ContactForm";
import AppNavbar from "../layouts/AppNavbar";
import Footer from "../layouts/Footer";
import { Col, Container, Row } from "react-bootstrap";

const ContactPage = () => {
  return (
    <div>
      <AppNavbar />
      <div className="header header-2">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container header-content-container-2">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-white">Contact </span>
          <span className="text-primary-red">Us </span>
        </h1>
      </div>
      <VerticalSpacer factor={3} />
      <div className="contact-form-container">
        <Container>
          <Row className="mt-4">
            <Col lg={9}>
              <ContactForm />
            </Col>
            <Col lg={3}>
              <p className="font-size-24" style={{ fontWeight: 400 }}>
                WHITEWOOD TRANSPORT
              </p>
              <span>
                2185 Goodman Road
                <br />
                Billings, MT 59101
              </span>
              <br />
              <br />
              <p className="contact-section-heading">SALES DEPARTMENT</p>
              <div className="contact-section">
                <p>
                  <span>Toll Free: </span>
                  <a
                    href="tel:(800) 228.5569"
                    className="text-primary-red underline-primary"
                  >
                    (800) 228.5569
                  </a>
                </p>
                <p>
                  <span>Local: </span>
                  <a
                    href="tel:(406) 245.8030"
                    className="text-primary-red underline-primary"
                  >
                    (406) 245.8030
                  </a>
                </p>
                <p>
                  <span>Fax: </span>
                  <a
                    href="tel:(406) 248.7850"
                    className="text-primary-red underline-primary"
                  >
                    (406) 248.7850
                  </a>
                </p>
              </div>
              <br />
              <p className="contact-section-heading">BROKERAGE DEPARTMENT</p>
              <div className="contact-section">
                <p>
                  <span>Toll Free: </span>
                  <a
                    href="tel:(800) 228.5569"
                    className="text-primary-red underline-primary"
                  >
                    (800) 228.5569
                  </a>
                </p>
                <p>
                  <span>Local: </span>
                  <a
                    href="tel:(406) 869.7368"
                    className="text-primary-red underline-primary"
                  >
                    (406) 869.7368
                  </a>
                </p>
                <p>
                  <span>Fax: </span>
                  <a
                    href="tel:(406) 248.7850"
                    className="text-primary-red underline-primary"
                  >
                    (406) 248.7850
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <VerticalSpacer factor={3} />
      <Container>
        <iframe
          loading="lazy"
          src="https://maps.google.com/maps?q=Whitewood%20Transport%20Inc&#038;t=m&#038;z=16&#038;output=embed&#038;iwloc=near"
          title="Whitewood Transport Inc"
          aria-label="Whitewood Transport Inc"
          width="100%"
          height="380px"
        ></iframe>
      </Container>
      <VerticalSpacer factor={2} />
      <Footer />
    </div>
  );
};

export default ContactPage;
