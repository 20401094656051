import images from "../assets/images";
import VerticalSpacer from "../components/common/VerticalSpacer";
import AppNavbar from "../layouts/AppNavbar";
import Footer from "../layouts/Footer";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routeNames } from "../utils/constants";

const HomePage = () => {
  return (
    <div>
      <AppNavbar />
      <div className="header">
        <img src={images.headerImage} alt="trucks" />
      </div>
      <div className="header-content-container">
        <h1 className="font-BebasNeue main-heading">
          <span className="text-primary-red">Driven </span>
          <span className="text-white">by efficiency </span>
          <br />
          <span className="text-white">delivering </span>
          <span className="text-primary-red">Excelence</span>
          <br />
        </h1>
        <span className="sub-heading text-white font-DMSans text-italic">
          Experience, Commitment, Expertise, Innovation
        </span>
      </div>
      <Container
        fluid
        className="h-section-1 position-relative"
        style={{ overflow: "hidden" }}
      >
        <VerticalSpacer factor={5} />
        <Row>
          <Col sm={6}>
            <div className="h-section-1-content">
              <p className="text-primary-red font-size-24">
                Full Logistics Solutions
              </p>
              <h2 className="font-BebasNeue">
                Proudly Serving Heavy Haul and Logistics Trucking
              </h2>
              <div>
                <Link
                  to={routeNames.instantQuotePage}
                  className="button submit-button center"
                  style={{ maxWidth: "300px" }}
                >
                  Instant Quote
                </Link>
              </div>
            </div>
          </Col>
          <Col sm={1} />
          <Col sm={5} className="p-0 pt-5 pb-5 center">
            <img
              src={images.craneImage}
              alt="Crane"
              width="100%"
              style={{ alignSelf: "flex-end" }}
            />
            <img
              alt="rectangle"
              src={images.rectangleRed}
              className="rectangle-1"
            />
          </Col>
        </Row>
      </Container>
      <div className="h-section-2">
        <div className="h-section-2-img-cont">
          <img src={images.truckImage} alt="trucks" />
        </div>
        <div className="h-section-2-content-container text-center">
          <Container>
            <Row className="align-center justify-content-between">
              <Col sm={5}>
                <div>
                  <p className="text-white font-size-24">
                    Pride in Professionalism
                  </p>
                  <h2 className="text-white font-BebasNeue">
                    <span className="text-primary-red">Stable</span> And
                    Flexible
                  </h2>
                  <Link
                    to={routeNames.instantQuotePage}
                    className="button submit-button center bg-white text-primary-red m-auto"
                    style={{ maxWidth: "300px" }}
                  >
                    Instant Quote
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="h-list-container">
                  <iframe
                    src="https://www.youtube.com/embed/Bi0M_Pz82z4?autoplay=1&mute=1"
                    className="iframe"
                    title="Truck Driver of the Year"
                  ></iframe>
                  <ul>
                    <li>Available 24/7</li>
                    <li>
                      Experience Staff with over 250 years of transport
                      experience
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="h-section-3">
        <Container fluid className="h-section-3-1">
          <Row>
            <Col sm={6} className="transport-desc-container">
              <div>
                <p className="ps-5 font-size-24">
                  Our specialty is the transportation of cranes and large
                  machinery from the mining and construction industry. But
                  regardless of weight, we do our very best to offer safe,
                  reliable, and affordable transportation solutions to each and
                  every one of our customers
                </p>
              </div>
            </Col>
            <Col sm={1} />
            <Col sm={5} className="transport-desc-container-2">
              <p className="text-primary-red font-size-24">
                Reliable and Affordable
              </p>
              <h2>
                <span className="text-primary-red font-BebasNeue">Expert</span>{" "}
                in Cranes and Large Machinery
              </h2>
              <VerticalSpacer />
              <Link
                to={routeNames.instantQuotePage}
                className="button submit-button center"
                style={{ maxWidth: "300px" }}
              >
                Instant Quote
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="h-section-4">
        <div className="h-section-4-1">
          <Container className="h-section-4-content">
            <Row>
              <Col xs={12} lg={8}>
                <div className="h-section-4-1-content">
                  <p className="text-white font-size-24 text-center">
                    Wide Service Points
                  </p>
                  <h2 className="font-BebasNeue text-white">
                    Broad Customer Base
                  </h2>
                  <p className="text-white font-size-24 text-start">
                    We service most points in the U.S. and Canada, operating
                    over 50 tractor/trailer combinations ranging from 5 to 11
                    axles and have the ability to broker freight with many other
                    carriers.
                  </p>
                  <Link
                    to={routeNames.instantQuotePage}
                    className="button submit-button center bg-white text-primary-red align-self-start"
                    style={{ maxWidth: "300px" }}
                  >
                    Instant Quote
                  </Link>
                </div>
              </Col>

              <Col xs={0} lg={4} />
            </Row>
          </Container>
        </div>
      </div>
      <div className="h-section-5 center direction-column text-center">
        <p className="text-primary-red font-size-24">
          Trusted Cargo & Freight Company
        </p>
        <h2>Multiple Year Recipient Awardee</h2>
        <Image
          src={images.montanaTrucking}
          alt="Montana Trucking"
          className="h5-img"
          fluid
        />
        <VerticalSpacer factor={2} />
        <div className="center">
          <img src={images.filledStar} alt="star" />
          <img src={images.filledStar} alt="star" />
          <img src={images.filledStar} alt="star" />
          <img src={images.filledStar} alt="star" />
          <img src={images.filledStar} alt="star" />
        </div>
        <VerticalSpacer factor={2} />
        <p className="font-size-24 h-section-5-content">
          As a recent multiple year recipient of the Motor Carrier of the Year
          Award from the Motor Carriers of Montana, we demonstrate our vision
          every day to be the very best at what we do.
        </p>
      </div>
      <div className="h-section-6">
        <div className="h-section-6-img-cont">
          <img src={images.truck3} alt="trucks" />
        </div>
        <div className="center direction-column h-100">
          <h2 className="font-BebasNeue text-white text-center">
            Ready to elevate your heavy haul experience?
          </h2>
          <div>
            <Link
              to={routeNames.instantQuotePage}
              className="button submit-button center"
              style={{ maxWidth: "300px" }}
            >
              Instant Quote
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
