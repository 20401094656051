import FaqCard from "../cards/FaqCard";
import { faqsData } from "../../utils/data";

const FaqSection = () => {
  return (
    <div className="w-100 d-flex align-center direction-column">
      {faqsData.map((faq) => (
        <FaqCard key={faq.id} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FaqSection;
