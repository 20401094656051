import VerticalSpacer from "../common/VerticalSpacer";
import { Card } from "react-bootstrap";

const VehicleCard = ({ icon, text, onClick }) => {
  return (
    <Card
      className="d-flex justify-center align-center direction-column vehicle-card"
      onClick={onClick}
    >
      <img src={icon} className="vehicleImage" alt={text} />
      <VerticalSpacer />
      <span className="text-center">{text}</span>
    </Card>
  );
};

export default VehicleCard;
