import images from "../assets/images";
import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { routeNames, strings } from "../utils/constants";

const routes = [
  { title: strings.instantQuote, path: routeNames.instantQuotePage },
  { title: strings.ownerOperators, path: routeNames.ownerOperators },
  { title: strings.blogs, path: routeNames.blogListing },
  { title: strings.gallery, path: routeNames.gallery },
  { title: strings.aboutUs, path: routeNames.aboutUs },
  { title: strings.contact, path: routeNames.contact },
];

const AppNavbar = () => {
  const location = useLocation();
  return (
    <Navbar expand="md" className="bg-transparent nav-container navbar-dark">
      <Container>
        <Link className="navbar-brand" to={routeNames.homepage}>
          <img
            src={images.wtLogo}
            width="62"
            height="62"
            className="d-inline-block align-top"
            alt="WT logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {routes.map((d) => (
              <Link key={d.title} className="nav-link" to={d.path}>
                {d.title}
                {d.path === location.pathname && <div className="nav-border" />}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
