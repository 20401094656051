import { apiRoutes } from "../utils/constants";

const NODE_PROD_API_URL = "https://api23.truckpedia.io/api/";
const NODE_DEV_API_URL = "http://127.0.0.1:3000/api/";

const baseUrl =
  process.env.NODE_ENV !== "production" ? NODE_DEV_API_URL : NODE_PROD_API_URL;

export const sendContactEmail = async (data) => {
  try {
    const response = fetch(`${baseUrl}${apiRoutes.sendEmail}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
