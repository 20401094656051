import OwnerOperatorspage from "./pages/OwnerOperatorsPage";
import Home from "./pages/HomePage";
import InstantQuotePage from "./pages/InstantQuotePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routeNames } from "./utils/constants";
import AboutUsPage from "./pages/AboutUsPage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import BlogList from "./pages/blog/BlogList";
import BlogDetail from "./pages/blog/BlogDetail";
import GalleryPage from "./pages/GalleryPage";
import ScrollToTop from "./components/functionality/ScrollToTop";

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path={routeNames.instantQuotePage}
          element={<InstantQuotePage />}
        />
        <Route
          path={routeNames.ownerOperators}
          element={<OwnerOperatorspage />}
        />
        <Route path={routeNames.aboutUs} element={<AboutUsPage />} />
        <Route path={routeNames.contact} element={<ContactPage />} />
        <Route path={routeNames.blogListing} element={<BlogPage />}>
          <Route index element={<BlogList />} />
          <Route path=":slug" element={<BlogDetail />} />
        </Route>
        <Route path={routeNames.gallery} element={<GalleryPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
