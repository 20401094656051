import { useState } from 'react';
import VerticalSpacer from '../../components/common/VerticalSpacer';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import DatePickerField from '../../components/forms/DatePickerField';
import {
  formatDate,
  formatQuoteEmail,
  showSnackbar,
} from '../../utils/helpers';
import { sendContactEmail } from '../../services/apis';
import { responseMessages } from '../../utils/constants';
import SuccessAlert from '../alerts/SuccessAlert';
import Snackbar from '../toasts/Snackbar';

const GetQuoteForm = ({ onClickBack, selectedVehicle }) => {
  const [validated, setValidated] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [commentFocused, setCommentFocused] = useState(false);
  const [formState, setFormState] = useState({
    pickupLocation: '',
    dropoffLocation: '',
    firstName: '',
    lastName: '',
    phone: '',
    emailAddress: '',
    commodityOfHaul: '',
    date: '',
    companyName: '',
    comment: '',
  });

  const _handleChange = (name, value) => {
    setFormState((st) => ({ ...st, [name]: value }));
  };

  const _handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    setSendEmailLoading(true);
    const data = {
      to:
        process.env.NODE_ENV !== 'production'
          ? [process.env.REACT_APP_DEV_EMAIL_TO]
          : [process.env.REACT_APP_PROD_EMAIL_TO],
      subject: `New quote request for ${
        selectedVehicle === 'Others' ? 'Other Equipment Types' : selectedVehicle
      }`,
      content: formatQuoteEmail(formState),
    };
    try {
      const response = await sendContactEmail(data);
      if (response && response.ok) {
        setShowSuccessAlert(true);
      } else {
        showSnackbar(responseMessages.contactFormFailure);
      }
    } catch (err) {
      showSnackbar(responseMessages.contactFormFailure);
    }
    setSendEmailLoading(false);
  };

  return (
    <Container>
      <Form
        method="POST"
        id="quote-form"
        onSubmit={_handleFormSubmit}
        validated={validated}
        noValidate
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !commentFocused) {
            event.preventDefault();
          }
        }}
      >
        <Row>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                First Name <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="firstName"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the first name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Last Name <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="lastName"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the last name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Email <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="email"
                  name="emailAddress"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {formState.email
                    ? 'Please enter a valid email address'
                    : 'Please enter an email address'}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Phone <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="phone"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the phone number
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Pick up Location <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="pickupLocation"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the pickup location
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Drop off Location <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="dropoffLocation"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the drop off location
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>
                Commodity to haul <span className="text-primary-red">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="commodityOfHaul"
                  onChange={(e) => _handleChange(e.target.name, e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the commodity of haul
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <DatePickerField
              value={formState.date}
              label={<span>Date of Pickup</span>}
              mode="single"
              onChange={(name, value) =>
                _handleChange(name, formatDate(new Date(value)))
              }
              name="date"
              minDate={new Date()}
            />
          </Col>
          <Col md={12} xl={6} className="mb-3">
            <Form.Group>
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                onChange={(e) => _handleChange(e.target.name, e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                name="comment"
                onChange={(e) => _handleChange(e.target.name, e.target.value)}
                style={{ borderWidth: '1.8px' }}
                as="textarea"
                rows={4}
                onFocus={() => setCommentFocused(true)}
                onBlur={() => setCommentFocused(false)}
              />
            </Form.Group>
          </Col>
        </Row>
        <VerticalSpacer />
        <Row>
          <Col md={6} className="mt-2">
            <div className="d-flex">
              <button
                className="button back-button"
                type="button"
                onClick={onClickBack}
              >
                Back
              </button>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div className="d-flex quote-submit-button">
              <button
                disabled={sendEmailLoading}
                className="button submit-button border-0"
                type="submit"
              >
                {sendEmailLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
      <SuccessAlert
        show={showSuccessAlert}
        onClose={() => {
          setShowSuccessAlert(false);
          onClickBack();
        }}
        heading="Success!"
        description={responseMessages.whitewoodQouteFormSuccess}
      />
      <Snackbar />
    </Container>
  );
};

export default GetQuoteForm;
