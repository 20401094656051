import Flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "./styles.css";
import { useState } from "react";
import { Form } from "react-bootstrap";

const DatePickerField = ({
  name,
  label,
  placeholder,
  value,
  mode,
  onChange,
  minDate,
}) => {
  const [picker, setPicker] = useState(null);

  const initFlatpickr = (ref) => {
    if (!picker && ref) {
      const newPicker = Flatpickr(ref, {
        mode: mode,
        dateFormat: "Y-m-d",
        defaultDate: value,
        minDate,
        onChange: (selectedDates) => {
          onChange(name, selectedDates);
        },
      });
      setPicker(newPicker);
    }
  };
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder}
        name={name}
        ref={initFlatpickr}
        value={value}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default DatePickerField;
